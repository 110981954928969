import { type ReactNode, type HTMLAttributes } from 'react';

import { Badge, type BadgeProps } from '@backyard-ui/core';

import { type ProductTag } from '@/infra/services/home/queries/modules/index.schemas';

export interface TagProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
  /**
   *
   */
  type?: ProductTag['type'];

  /**
   *
   */
  children: ReactNode;
}

const APPEARANCE_MAP: {
  [key in ProductTag['type']]: BadgeProps['appearance'];
} = {
  freeShipping: 'critical',
  exclusive: 'primary',
  outlet: 'neutral',
  news: 'neutral',
  lowestprice: 'warning',
};

function Tag(props: TagProps) {
  const { type, ...rest } = props;

  if (!type) {
    return null;
  }

  const appearance = APPEARANCE_MAP[type];

  return (
    <Badge
      data-testid={`ProductThumb-tag--${appearance}`}
      appearance={appearance}
      UNSAFE_className="[&>span]:max-md:text-xs [&>span]:max-md:leading-none"
      {...rest}
    />
  );
}

export default Tag;
