import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import {
  type Response,
  type Body,
} from '@/infra/services/wishlist/create-wishlist';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';

type Options = UseMutationOptions<Response, WishlistError, Body>;

export const useCreateWishlist = (options: Options) => {
  return useMutation<Response, WishlistError, Body>({
    mutationFn: Services.Wishlist.createWishlist,
    ...options,
  });
};
