import { Flex, Text } from '@backyard-ui/core';

import { type Product } from '@/infra/services/home/queries/modules/index.schemas';

export interface StripeProps {
  /**
   *
   */
  discount?: Product['price']['discount'];

  /**
   *
   */
  isOutlet?: Product['meta']['isOutlet'];

  /**
   *
   */
  className?: string;
}

function Stripe(props: StripeProps) {
  const { discount, isOutlet, className, ...rest } = props;

  const textColor = isOutlet ? 'green-500' : 'yellow-400';

  return (
    <Flex
      justify="space-between"
      className={className}
      data-testid="ProductThumb-stripe"
      {...rest}
    >
      <Text
        color={textColor}
        size="md"
        weight="bold"
        transform="uppercase"
        asChild
      >
        <span>Oferta</span>
      </Text>

      <Text color={textColor} size="md" weight="bold" asChild>
        <span>-{discount?.percentage}%</span>
      </Text>
    </Flex>
  );
}

export default Stripe;
