import { Flex, Heading, Stack, Text } from '@backyard-ui/core';

import { type LastContents } from '@/infra/services/home/queries/modules/index.schemas';
import { Carousel } from '@/presenters/components/common/Carousel';
import { Image } from '@/presenters/components/common/Image';
import { Wishlist } from '@/presenters/components/common/Wishlist';

function Contents(props: LastContents) {
  const { title, items } = props;

  return (
    <Stack direction="column" spacing={4}>
      <Heading size="2xl" asChild>
        <h2>{title}</h2>
      </Heading>

      <Carousel
        controls={{
          arrows: true,
        }}
        options={{
          slidesToScroll: 'auto',
          containScroll: 'trimSnaps',
          inViewThreshold: 1,
          dragFree: true,
        }}
      >
        {items.map(({ id, image, title, meta, publishDate, url }) => (
          <div
            key={id}
            className="group flex w-[theme('width.72')] min-w-[theme('width.72')] flex-col overflow-hidden rounded-md border border-gray-100 bg-white md:min-w-[theme('width.80')] [&:not(:last-child)]:mr-4"
            data-testid="content"
          >
            <div className="h-44 overflow-hidden">
              <a href={url}>
                <Image
                  src={image.url}
                  alt={image.alt}
                  width={image.size.width}
                  height={image.size.height}
                  className="h-full w-full transition-transform duration-100 ease-in group-hover:scale-105 md:h-[200px]"
                />
              </a>
            </div>

            <Stack
              direction="column"
              spacing={2}
              className="flex min-h-[theme('height.32')] flex-col justify-between p-4"
            >
              <a href={url}>
                <Heading color="neutral-900" noOfLines={2} size="md" asChild>
                  <h4>{title}</h4>
                </Heading>
              </a>

              <Flex justify="space-between" align="end">
                <Text align="left" color="gray-400" size="md" asChild>
                  <span>
                    {new Date(publishDate).toLocaleDateString('pt-BR')}
                  </span>
                </Text>

                <Wishlist
                  itemType="content"
                  id={id}
                  inWishlist={meta?.inWishlist || false}
                />
              </Flex>
            </Stack>
          </div>
        ))}
      </Carousel>
    </Stack>
  );
}

export default Contents;
