import { AlertDialog, Button } from '@backyard-ui/core';
import { isBrowser } from '@backyard-ui/utils';

import { WishlistButton } from '../WishlistButton';

function LoggedOutAlert() {
  return (
    <AlertDialog.Root size="md">
      <AlertDialog.Trigger asChild>
        <WishlistButton inWishlist={false} />
      </AlertDialog.Trigger>

      <AlertDialog.Content>
        <AlertDialog.Title>Adicionar à lista de favoritos</AlertDialog.Title>

        <AlertDialog.Description>
          Para utilizar as listas de favoritos é necessário efetuar login,
          deseja realizar esta operação?
        </AlertDialog.Description>
        <AlertDialog.Footer>
          <AlertDialog.Cancel asChild>
            <Button appearance="neutral">Não</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button asChild>
              <a
                href={
                  isBrowser
                    ? `/login?redirect=${window.location.href}`
                    : '/login'
                }
              >
                Sim
              </a>
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

export default LoggedOutAlert;
