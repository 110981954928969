import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Params } from '@/infra/services/wishlist/remove-item-from-wishlist';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';

type Options = UseMutationOptions<void, WishlistError, Params>;

export const useRemoveItemFromWishlist = (options: Options) => {
  return useMutation<void, WishlistError, Params>({
    mutationFn: Services.Wishlist.removeItemFromWishlist,
    ...options,
  });
};
