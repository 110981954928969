import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    mb-6
    mt-8

    [&_.wrapper]:w-full
    [&_.wrapper]:bg-gray-100
    [&_.wrapper]:px-3

    [&_a]:text-primary-600
    [&_a]:transition-colors
    [&_a]:duration-100
    hover:[&_a]:text-primary-800

    [&_blockquote]:my-4
    [&_blockquote]:pl-10

    [&_button]:inline-flex
    [&_button]:select-none
    [&_button]:appearance-none
    [&_button]:items-center
    [&_button]:justify-center
    [&_button]:gap-1.5
    [&_button]:space-x-2
    [&_button]:rounded-md
    [&_button]:border-2
    [&_button]:border-transparent
    [&_button]:bg-primary-700
    [&_button]:px-4
    [&_button]:py-1.5
    [&_button]:font-semibold
    [&_button]:text-white
    [&_button]:no-underline
    [&_button]:outline-none
    [&_button]:transition
    [&_button]:duration-100
    [&_button]:ease-in
    hover:[&_button]:bg-primary-800
    focus:[&_button]:ring-2
    focus:[&_button]:ring-primary-300
    active:[&_button]:bg-primary-900
    disabled:[&_button]:cursor-not-allowed
    disabled:[&_button]:border-neutral-100
    disabled:[&_button]:bg-neutral-100
    disabled:[&_button]:text-neutral-300
    disabled:[&_button]:shadow-none
    disabled:hover:[&_button]:bg-neutral-100

    [&_em]:italic

    [&_h1]:mb-2
    [&_h1]:mt-6
    [&_h1]:font-bold
    [&_h1]:heading-xl

    [&_h2]:mb-2
    [&_h2]:mt-6
    [&_h2]:font-bold
    [&_h2]:heading-lg

    [&_h3]:mb-2
    [&_h3]:mt-6
    [&_h3]:text-xl
    [&_h3]:font-bold
    [&_h3]:md:heading-md

    [&_h4]:font-semibold

    [&_iframe]:!inline
    [&_iframe]:max-h-[225px]
    [&_iframe]:!w-full
    [&_iframe]:px-2
    [&_iframe]:sm:max-h-[300px]
    [&_iframe]:sm:!max-w-[450px]
    [&_iframe]:md:max-h-[315px]
    [&_iframe]:md:!max-w-[560px]
    [&_iframe]:md:p-2

    [&_img]:mx-auto
    [&_img]:w-full
    [&_img]:max-w-full
    [&_img]:md:w-1/2

    [&_ol]:my-4
    [&_ol]:px-10

    [&_p]:my-3
    [&_p]:text-lg

    [&_strong]:font-bold

    [&_u]:underline

    [&_ul]:list-disc
    [&_ul]:pl-4
    [&_ul]:text-lg

    [&_ul_li]:mb-2
  `,
});
