'use client';

import {
  Button,
  FormControl,
  Label,
  Stack,
  TextField,
  type IconButtonProps,
} from '@backyard-ui/core';

export interface CreateButtonProps
  extends Pick<IconButtonProps, 'isLoading' | 'isDisabled'> {
  onCreate: (name: string) => void;
}

const CreateButton = (props: CreateButtonProps) => {
  const { isLoading, isDisabled, onCreate } = props;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const value = data.get('new-list-name');
        value && onCreate(value as string);
      }}
      className="shrink-0 px-1"
    >
      <Stack align="flex-end" spacing="2">
        <FormControl.Root>
          <Label>Adicionar a uma nova lista</Label>
          <TextField.Root size="sm">
            <TextField.Input name="new-list-name" />
            <TextField.Slot>
              <Button
                type="submit"
                size="xs"
                isDisabled={isDisabled}
                isLoading={isLoading}
              >
                Criar lista
              </Button>
            </TextField.Slot>
          </TextField.Root>
        </FormControl.Root>
      </Stack>
    </form>
  );
};

CreateButton.displayName = 'CreateButton';

export default CreateButton;
