import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import {
  type Response,
  type Body,
  type Params,
} from '@/infra/services/wishlist/add-item-to-wishlist';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';

type Options = UseMutationOptions<Response, WishlistError, Body & Params>;

export const useAddItemToWishlist = (options: Options) => {
  return useMutation<Response, WishlistError, Body & Params>({
    mutationFn: Services.Wishlist.addItemToWishlist,
    ...options,
  });
};
