import dynamic from 'next/dynamic';

import { SimpleGrid } from '@backyard-ui/core';
import { useMediaQuery } from '@backyard-ui/hooks';

import { type Banners as IBanners } from '@/infra/services/home/queries/modules/index.schemas';
import { GtmDataAttributesGenerator } from '@/infra/tracking/gtm-data-attributes';
import * as tracker from '@/infra/tracking/tracker';
import { Image } from '@/presenters/components/common/Image';
import { useRunOnce } from '@/presenters/hooks/use-run-once';
import { useUrl } from '@/presenters/hooks/use-url';
import { useGlobalStore } from '@/presenters/store/global';

const Countdown = dynamic(
  () => import('../Countdown').then((mod) => mod.Countdown),
  {
    ssr: false,
  }
);

interface BannersProps extends IBanners {
  onClick?: () => void;
}

function Banners(props: Readonly<BannersProps>) {
  const { items, size, type, onClick } = props;
  const isDesktop = useMediaQuery('lg');

  const region = useGlobalStore((state) => state.user.session.region);
  const location = useUrl();

  useRunOnce(() => {
    tracker.onBannerView({
      items,
      moduleType: type,
      region: region,
    });
  }, Boolean(items.length) && Boolean(region));

  return (
    <SimpleGrid
      columns={{
        initial: 1,
        md: size,
      }}
      gapX="4"
    >
      {items?.map(
        ({ image, id, url, countdown, title, elementType }, index) => {
          const imageData = !isDesktop ? image.mobile : image.desktop;

          if (!imageData) {
            return null;
          }

          return (
            <div
              key={id}
              className="relative"
              data-testid="banner"
              {...GtmDataAttributesGenerator.banner({
                elementType,
                type,
                name: title,
                index: index,
                listName: image.alt,
              })}
            >
              {countdown?.end && (
                <div className="absolute bottom-0 left-0 right-0 top-auto z-10 lg:left-auto">
                  <Countdown endDate={new Date(countdown?.end)} />
                </div>
              )}
              <a
                href={url}
                onClick={(event) => {
                  onClick?.();

                  event.preventDefault();

                  tracker.onBannerClick({
                    imageAlt: image.alt,
                    url: url,
                    isMobile: !isDesktop,
                    position: index + 1,
                    moduleType: type,
                    location: location,
                    title: title,
                  });

                  window.location.href = url;
                }}
              >
                <Image
                  src={imageData.url}
                  alt={image.alt}
                  width={imageData.size.width}
                  height={imageData.size.height}
                  className="block w-full min-w-full bg-gray-100 object-cover object-center"
                />
              </a>
            </div>
          );
        }
      )}
    </SimpleGrid>
  );
}

export default Banners;
