import { SimpleGrid } from '@backyard-ui/core';

import { type Informatives } from '@/infra/services/home/queries/modules/index.schemas';
import { GtmDataAttributesGenerator } from '@/infra/tracking/gtm-data-attributes';
import * as tracker from '@/infra/tracking/tracker';
import { Image } from '@/presenters/components/common/Image';
import { useRunOnce } from '@/presenters/hooks/use-run-once';
import { useGlobalStore } from '@/presenters/store/global';

function Informative(props: Informatives) {
  const { items, size, type, title } = props;

  const region = useGlobalStore((state) => state.user.session.region);

  useRunOnce(() => {
    tracker.onBannerView({
      items,
      moduleType: type,
      region: region,
    });
  }, Boolean(items.length) && Boolean(region));

  return (
    <SimpleGrid columns={size} gapX="4">
      {items.map(({ id, image, elementType }, index) => (
        <div
          key={id}
          data-testid="informative"
          {...GtmDataAttributesGenerator.banner({
            elementType,
            type,
            name: image.alt,
            index: index,
            listName: title,
          })}
        >
          <Image
            src={image.url}
            alt={image.alt}
            width={image.size.width}
            height={image.size.height}
            className="w-full"
          />
        </div>
      ))}
    </SimpleGrid>
  );
}

export default Informative;
