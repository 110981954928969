import { memo } from 'react';

import DOMPurify from 'isomorphic-dompurify';

import { styles } from './Markdown.styles';

interface MarkdownProps {
  content: string;
}

function Markdown(props: Readonly<MarkdownProps>) {
  const { content } = props;

  return (
    <div
      className={styles()}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'] }),
      }}
    />
  );
}

export default memo<MarkdownProps>(Markdown);
