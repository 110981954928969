import dynamic from 'next/dynamic';

import { Stack, Badge, Flex, Heading, Text, Spacer } from '@backyard-ui/core';

import { type BeInspiredContents } from '@/infra/services/home/queries/modules/index.schemas';
import { Carousel } from '@/presenters/components/common/Carousel';
import { Image } from '@/presenters/components/common/Image';

const WishlistButton = dynamic(() =>
  import('@/presenters/components/common/Wishlist').then((mod) => mod.Wishlist)
);

function ContentGallery(props: BeInspiredContents) {
  const { title, items } = props;

  return (
    <Stack direction="column" spacing={4}>
      <Heading size="2xl" asChild>
        <h2>{title}</h2>
      </Heading>

      <Carousel
        controls={{
          arrows: true,
        }}
        options={{
          slidesToScroll: 'auto',
          containScroll: 'trimSnaps',
          inViewThreshold: 1,
          dragFree: true,
        }}
      >
        {items.map(({ id, image, title, meta, publishDate, url }) => (
          <div
            key={id}
            className="group relative flex h-[280px] w-[280px] shrink-0 flex-col overflow-hidden rounded-md border border-gray-100 bg-white p-4 [&:not(:last-child)]:mr-4"
          >
            <Image
              src={image.url}
              alt={image.alt}
              width={image.size.width}
              height={image.size.height}
              className="absolute inset-0 h-full scale-105 object-cover transition-transform duration-100 ease-in group-hover:scale-100"
            />

            <div className="relative flex h-full w-full flex-col justify-between">
              <Flex direction="column" align="flex-start">
                <Badge variant="subtle">{meta?.category}</Badge>

                <Spacer axis="vertical" size={4} />

                <a href={url} title={title}>
                  <Heading color="white" noOfLines={2} size="md" asChild>
                    <h4>{title}</h4>
                  </Heading>
                </a>

                <Spacer axis="vertical" size={1} />

                <Text color="white" size="md" asChild>
                  <span>
                    {new Date(publishDate).toLocaleDateString('pt-BR')}
                  </span>
                </Text>
              </Flex>

              <Flex justify="end">
                <WishlistButton
                  itemType="content"
                  id={id}
                  inWishlist={meta?.inWishlist || false}
                />
              </Flex>
            </div>
          </div>
        ))}
      </Carousel>
    </Stack>
  );
}

export default ContentGallery;
