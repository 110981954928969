'use client';

import {
  Button,
  Stack,
  Text,
  Flex,
  type IconButtonProps,
} from '@backyard-ui/core';

import {
  type Wishlist,
  type WishlistPictures,
} from '@/infra/services/home/queries/wishlist/index.schemas';
import { Image } from '@/presenters/components/common/Image';

export interface ListItemProps
  extends Pick<Wishlist, 'name' | 'products' | 'contents'> {
  onAdd: () => void;
  picture?: WishlistPictures['list'];
  isDisabled: IconButtonProps['isDisabled'];
  isLoading: IconButtonProps['isLoading'];
}

function ListItem(props: ListItemProps) {
  const { name, products, contents, picture, onAdd, isLoading, isDisabled } =
    props;

  return (
    <Stack spacing="1" align="center" justify="space-between">
      <Stack spacing="2" align="center">
        <Image
          alt={`Capa da lista ${name}`}
          width={48}
          height={48}
          className="h-12 w-12 shrink-0 rounded-md object-cover"
          src={picture || '/images/placeholder_no_picture.svg'}
        />
        <Flex direction="column">
          <Text size="sm" weight="bold" noOfLines={1} asChild>
            <span>{name}</span>
          </Text>
          <Text size="sm" noOfLines={1} asChild>
            <span>
              {products} {products > 1 ? 'produtos' : 'produto'}, {contents}{' '}
              {contents > 1 ? 'conteúdos' : 'conteúdo'}
            </span>
          </Text>
        </Flex>
      </Stack>

      <Button
        variant="outline"
        size="xs"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onAdd}
      >
        <span>Adicionar</span>
      </Button>
    </Stack>
  );
}

export default ListItem;
