import { memo } from 'react';

import { Button, Heading, Skeleton, Stack, Text } from '@backyard-ui/core';

import { type ModularContent } from '@/infra/services/modular-content/model';
import { Carousel } from '@/presenters/components/common/Carousel';
import { Voucher } from '@/presenters/components/common/Vouchers/Voucher';
import { useGlobalStore } from '@/presenters/store/global';
import { useModularContentStore } from '@/presenters/store/modular-content';

import { useVouchers } from './use-vouchers';

const LOYALTY_ACTIVE_STATUSES = [1, 7];

interface VouchersCarouselProps extends ModularContent.Vouchers {}

function VouchersCarousel(props: VouchersCarouselProps) {
  const { id: moduleId, title } = props;

  const { userControls, userResources, loyaltyProfile } = useGlobalStore(
    (state) => ({
      userControls: state.user.controls,
      userResources: state.user.resources,
      loyaltyProfile: state.user.loyaltyProfile,
    })
  );

  const contentId = useModularContentStore((state) => state.id);

  const { data, isFetched } = useVouchers({
    userId: userResources?.id ?? null,
    contentId,
    moduleId,
  });

  const hasAccess =
    userControls?.isLoggedIn &&
    LOYALTY_ACTIVE_STATUSES.includes(loyaltyProfile?.status || 0);

  const isFetchingLoyaltyProfile = userControls?.isLoggedIn && !loyaltyProfile;

  if (!isFetched || !userResources || isFetchingLoyaltyProfile) {
    return (
      <section className="mt-8 py-4">
        <Stack direction="column" spacing={4}>
          <Skeleton
            width="250px"
            height="34px"
            data-testid="recommended-products-loading"
          />
          <Skeleton width="350px" height="24px" />
          <Stack className="overflow-hidden">
            {Array.from({ length: 8 })
              .fill('')
              .map((_, index) => (
                <Skeleton
                  key={index}
                  width="172px"
                  height="300px"
                  UNSAFE_className="shrink-0"
                />
              ))}
          </Stack>
          <Skeleton width="100%" height="40px" />
        </Stack>
      </section>
    );
  }

  if (!data?.length) {
    return null;
  }

  return (
    <section className="mt-8 py-4">
      <Stack direction="column">
        {title && <Heading size="2xl">{title}</Heading>}
        {!hasAccess && (
          <Text>
            Cupons de desconto disponíveis apenas para participantes do Programa
            Fidelidade.
          </Text>
        )}
        <Carousel
          controls={{
            arrows: true,
          }}
          options={{
            containScroll: 'trimSnaps',
            dragFree: true,
          }}
        >
          {data.map((coupon) => (
            <div className="[&:not(:last-child)]:mr-4" key={coupon.id}>
              <Voucher id={coupon.id} isDisabled={!hasAccess} />
            </div>
          ))}
        </Carousel>
        {!hasAccess && (
          <Button>Participe do Programa de Fidelidade e ative</Button>
        )}
      </Stack>
    </section>
  );
}

export default memo(VouchersCarousel);
