import { type Banners as ModularBanner } from '@/infra/services/home/queries/modules/index.schemas';
import { type Response as GetBannersAdsResponse } from '@/infra/services/new-tail/get-banners-ads';

interface Banner extends ModularBanner {
  retailMedia: {
    clickUrl: string;
    impressionUrl: string;
    viewUrl: string;
  };
}

const buildModularBannersFromAd = (bannersAdsData?: GetBannersAdsResponse) => {
  const modularBanners: Banner[] = [];

  if (bannersAdsData?.fullBanner[0]) {
    const {
      ad_id,
      click_url,
      destination_url,
      impression_url,
      media_url,
      view_url,
    } = bannersAdsData.fullBanner[0];

    modularBanners.push({
      type: 'banner',
      title: 'Banner Patrocinado',
      size: 1,
      items: [
        {
          id: ad_id,
          image: {
            alt: 'Banner Patrocinado',
            desktop: {
              size: { width: 980, height: 120 },
              url: media_url,
            },
            mobile: {
              size: { width: 980, height: 120 },
              url: media_url,
            },
          },
          elementType: 'promotion',
          title: 'Banner Patrocinado',
          url: destination_url,
        },
      ],
      retailMedia: {
        clickUrl: click_url,
        impressionUrl: impression_url,
        viewUrl: view_url,
      },
    });
  }

  return modularBanners;
};

export default buildModularBannersFromAd;
