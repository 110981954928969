import {
  type UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Response } from '@/infra/services/modular-content/get-modules';
import { useGlobalStore } from '@/presenters/store/global';

type InfiniteOptions = Omit<
  UseInfiniteQueryOptions<Response, Error, Response>,
  'queryKey' | 'queryFn' | 'getNextPageParam' | 'staleTime'
>;

type Data = {
  slug: string;
};

export function useGetModulesInfinite(data: Data, options?: InfiniteOptions) {
  const region = useGlobalStore((state) => state.user.session.region);

  const initialData =
    typeof options?.initialData === 'function'
      ? options?.initialData()
      : options?.initialData;

  return useInfiniteQuery<Response, Error, Response>({
    ...options,
    initialData,
    queryKey: [`modules-infinity-${data.slug}`, region, initialData],
    queryFn: ({ pageParam = 1 }) => {
      if (pageParam === 1 && initialData) {
        return initialData.pages[0];
      }

      return Services.ModularContent.getModules({
        page: pageParam,
        slug: data.slug,
      });
    },
    getNextPageParam: ({ meta }) => {
      if (meta.page < meta.pages) {
        return meta.page + 1;
      }
      return undefined;
    },
  });
}
