'use client';

import { useEffect, useState } from 'react';

import {
  Button,
  Text,
  Drawer,
  Stack,
  Skeleton,
  ScrollArea,
  toast,
} from '@backyard-ui/core';

import { useGlobalStore } from '@/presenters/store/global';

import { CreateButton } from './components/CreateButton';
import { ListItem } from './components/ListItem';
import { LoggedOutAlert } from './components/LoggedOutAlert';
import { WishlistButton } from './components/WishlistButton';
import {
  useAddItemToWishlist,
  useCreateWishlist,
  useGetWishlistsInfinite,
  useRemoveItemFromWishlist,
} from './hooks';
import { buildFeedbackMessages } from './utils/build-feedback-messages';
import { styles } from './Wishlist.styles';

const LISTS_PER_PAGE = 10;

interface CommonProps {
  inWishlist: boolean;
  onAdd?: (listName: string) => void;
  onCreate?: (listName: string) => void;
  onRemove?: () => void;
}

type ItemProps =
  | {
      id: number;
      itemType: 'product';
    }
  | {
      id: string;
      itemType: 'content';
    };

export type WishlistProps = CommonProps & ItemProps;

function Wishlist(props: WishlistProps) {
  const { id, itemType, onAdd, onCreate, onRemove, inWishlist } = props;

  const [isInWishlist, setIsInWishlist] = useState(inWishlist);
  const [isOpen, setIsOpen] = useState(false);

  const isAuthenticated = useGlobalStore(
    (state) => state.user.controls?.isLoggedIn
  );

  const isProduct = itemType === 'product';
  const serviceType = itemType === 'product' ? 'products' : 'contents';

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isInitialLoading,
    isFetchingNextPage,
    isError,
  } = useGetWishlistsInfinite({ enabled: isOpen });

  const classNames = styles({});

  const addMutation = useAddItemToWishlist({
    onSuccess: (data) => {
      setIsInWishlist(true);
      toast(
        buildFeedbackMessages({
          status: 'success',
          message: `${isProduct ? 'Produto' : 'Conteúdo'} adicionado a "${
            data.data?.name
          }"`,
        })
      );
    },
    onError: (error, data) => {
      toast(
        buildFeedbackMessages({
          status: 'error',
          message:
            error.errors?.[0].message ||
            `Não conseguimos adicionar o ${
              isProduct ? 'produto' : 'conteúdo'
            } à lista "${data.slug}"`,
        })
      );
    },
    onSettled: () => setIsOpen(false),
  });

  const removeMutation = useRemoveItemFromWishlist({
    onSuccess: () => {
      setIsInWishlist(false);
      toast(
        buildFeedbackMessages({
          status: 'success',
          message: ` ${
            isProduct ? 'Produto' : 'Conteúdo'
          } removido da lista de favoritos`,
        })
      );
    },
    onError: () => {
      toast(
        buildFeedbackMessages({
          status: 'error',
          message: `Não conseguimos remover o ${
            isProduct ? 'produto' : 'conteúdo'
          } da lista de favoritos`,
        })
      );
    },
    onSettled: () => setIsOpen(false),
  });

  const createWishlist = useCreateWishlist({
    onSuccess: (_, data) => {
      setIsInWishlist(true);
      toast(
        buildFeedbackMessages({
          status: 'success',
          message: `${
            isProduct ? 'Produto' : 'Conteúdo'
          } adicionado à nova lista "${data.name}"`,
        })
      );
    },
    onError: (error, data) => {
      toast(
        buildFeedbackMessages({
          status: 'error',
          message:
            error?.errors?.[0].message ||
            `Não conseguimos criar a lista "${data.name}"`,
        })
      );
    },
    onSettled: () => setIsOpen(false),
  });

  useEffect(() => {
    if (isError && isOpen) {
      setIsOpen(false);
      toast(
        buildFeedbackMessages({
          status: 'error',
          message:
            'Não conseguimos carregar suas listas de desejos. Por favor, tente novamente',
        })
      );
    }
  }, [isError, isOpen]);

  if (!isAuthenticated) {
    return <LoggedOutAlert />;
  }

  if (isInWishlist) {
    return (
      <WishlistButton
        inWishlist={isInWishlist}
        onClick={() => {
          onRemove && onRemove();
          // @ts-ignore
          removeMutation.mutate({
            id,
            type: serviceType,
          });
        }}
      />
    );
  }

  return (
    <Drawer.Root
      size="lg"
      isOpen={isOpen}
      onOpenChange={() => setIsOpen((prev) => !prev)}
    >
      <Drawer.Trigger asChild>
        <WishlistButton inWishlist={isInWishlist} />
      </Drawer.Trigger>

      <Drawer.Content>
        <div className={classNames.title()}>
          <Drawer.Title>Adicionar à lista de favoritos</Drawer.Title>
        </div>
        <Drawer.Description>
          <Stack
            direction="column"
            justify="flex-start"
            spacing="4"
            className={classNames.list()}
          >
            <CreateButton
              isLoading={createWishlist.isLoading}
              isDisabled={addMutation.isLoading}
              onCreate={(name) => {
                onCreate && onCreate(name);
                createWishlist.mutate({
                  name,
                  [isProduct ? 'products' : 'contents']: [{ id }],
                });
              }}
            />
            <Text>Adicionar a uma lista existente</Text>

            {isInitialLoading ? (
              <Stack direction="column">
                {Array.from({ length: LISTS_PER_PAGE })
                  .fill('')
                  .map((_, index) => (
                    <Skeleton key={index} height="48px" width="100%" />
                  ))}
              </Stack>
            ) : (
              <ScrollArea UNSAFE_className={classNames.scrollArea()}>
                <Stack direction="column">
                  {data?.pages?.map((page, index) => {
                    if (page.data.length === 0) {
                      return (
                        <Text size="md" key={index}>
                          Você ainda não possui nenhuma lista.
                        </Text>
                      );
                    }

                    return page.data.map((item) => (
                      <ListItem
                        key={item.id}
                        name={item.name}
                        products={item.products}
                        contents={item.contents}
                        picture={item.pictures?.list}
                        isDisabled={
                          addMutation.isLoading || createWishlist.isLoading
                        }
                        isLoading={
                          addMutation.data?.data?.slug === item.slug &&
                          addMutation.isLoading
                        }
                        onAdd={() => {
                          onAdd && onAdd(item.name);
                          addMutation.mutate({
                            slug: item.slug,
                            id,
                            type: serviceType,
                          });
                        }}
                      />
                    ));
                  })}

                  {hasNextPage && (
                    <div className={classNames.seeMore()}>
                      <Button
                        variant="ghost"
                        size="xs"
                        disabled={addMutation.isLoading}
                        isLoading={isFetchingNextPage}
                        onClick={() => fetchNextPage()}
                        isFullWidth
                      >
                        Ver mais
                      </Button>
                    </div>
                  )}
                </Stack>
              </ScrollArea>
            )}
          </Stack>
        </Drawer.Description>
        <Drawer.CloseButton />
      </Drawer.Content>
    </Drawer.Root>
  );
}

export default Wishlist;
